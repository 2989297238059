<template>
	<div class="">
		<div class="sqBox margin_auto">
			<img
				src="https://res.metaera.hk/resources/assets/images/sq/23.png"
				alt=""
				class="sqimg"
			/>
			<div class="sqCon margin_auto">
				<div
					class="sqBoxItem flex align_items_c justify_content_sb pointer"
					@click="toUrl(config.twitter.value)"
				>
					<div class="flex align_items_c">
						<img
							src="https://res.metaera.hk/resources/assets/images/sq/1058.png"
							alt=""
							class="sqimg1"
						/>
						<div style="margin-left: 10px">{{ config.twitter.name }}</div>
					</div>
					<img
						src="https://res.metaera.hk/resources/assets/images/sq/1062.png"
						alt=""
						class="sqimg2"
					/>
				</div>
				<div
					class="sqBoxItem flex align_items_c justify_content_sb pointer"
					@click="toUrl(config.telegram.value)"
				>
					<div class="flex align_items_c">
						<img
							src="https://res.metaera.hk/resources/assets/images/sq/1059.png"
							alt=""
							class="sqimg1"
						/>
						<div style="margin-left: 10px">{{ config.telegram.name }}</div>
					</div>

					<img
						src="https://res.metaera.hk/resources/assets/images/sq/1062.png"
						alt=""
						class="sqimg2"
					/>
				</div>
				<div
					class="sqBoxItem flex align_items_c justify_content_sb pointer"
					@click="toUrl(config.official_website.value)"
				>
					<div class="flex align_items_c">
						<img
							src="https://res.metaera.hk/resources/assets/images/sq/1060.png"
							alt=""
							class="sqimg1"
						/>
						<div style="margin-left: 10px">{{
							config.official_website.name
						}}</div>
					</div>

					<img
						src="https://res.metaera.hk/resources/assets/images/sq/1062.png"
						alt=""
						class="sqimg2"
					/>
				</div>
				<div
					class="sqBoxItem flex align_items_c justify_content_sb pointer"
					@click="toUrl(config.gmail.value)"
				>
					<div class="flex align_items_c">
						<img
							src="https://res.metaera.hk/resources/assets/images/sq/1061.png"
							alt=""
							class="sqimg1"
						/>
						<div style="margin-left: 10px">{{ config.gmail.name }}</div>
					</div>

					<img
						src="https://res.metaera.hk/resources/assets/images/sq/1062.png"
						alt=""
						class="sqimg2"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getAppConfig } from "@/api/home";
export default {
	name: "",
	data() {
		return {
			config: {},
		};
	},
	created() {
		this.getAppConfig();
	},
	methods: {
		getAppConfig() {
			getAppConfig({})
				.then((res) => {
					if (res.code == 200) {
						this.config = res.data;
					}
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
		toUrl(url) {
			window.location.href = url;
		},
	},
};
</script>

<style scoped>
.sqBox {
	width: 750px;
	height: 825px;
	background: #ffffff;
	box-shadow: 0px 3px 26px 1px rgba(0, 0, 0, 0.16);
	border-radius: 20px 20px 20px 20px;
	opacity: 1;
	margin-top: 89px;
	margin-bottom: 91px;
}

.sqimg {
	width: 100%;
	height: 181px;
}

.sqCon {
	width: 490px;
	margin-top: 42px;
}

.sqBoxItem {
	width: 100%;
	height: 60px;
	background: #f9f9f9;
	border-radius: 10px 10px 10px 10px;
	opacity: 1;
	margin-top: 20px;
}

.sqimg1 {
	width: 66px;
}

.sqBoxItem div {
	font-size: 18px;
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	color: #000000;
}

.sqimg2 {
	width: 16px;
	margin-right: 20px;
}
</style>
